import Vue from 'vue'
import CommonAPI from '~/api/common'

export default function ({store, app}) {
    const CommonPlugin = {
        install(Vue, options) {

            // 전역 메서드 or 속성 추가
            Vue.globalMethod = function () {
                return 'globalMethod!!'
            }

            // 전역 인스턴스 메서드 추가
            Vue.prototype.$baseCode = function (code) {
                return store.getters.getBaseCode.find(item => item.code == code).value
            }

            // 숫자여부
            Vue.prototype.$isNumber = function (obj) {
                return !Array.isArray(obj) && (obj - parseFloat(obj) + 1) >= 0;
            }

            // 사이트 코인 정의
            Vue.prototype.$siteCoin = function () {
                return 'SKG'
            }

            // 사이트 주마켓
            Vue.prototype.$marketCoin = function () {
                return 'KRW'
            }

            // 기축통화
            Vue.prototype.$currency = function () {
                return 'KRW'
            }

            // 관리자 ID (중앙지갑)
            Vue.prototype.$coinAdminId = function () {
                return 1000000
            }

            // ETH/ERC20 전송 가스비
            Vue.prototype.$gasPrice = function () {
                return 5 / 10000 // 0.0005
            }

            // NEW 게시물 여부
            Vue.prototype.$isNewPost = function (timestamp, day) {
                if (day == undefined) day = 1  // default
                var nowDate = new Date()
                var beforeTimestamp = Date.parse(nowDate) - day * 1000 * 60 * 60 * 24
                return timestamp > beforeTimestamp ? true : false
            }

            // 관리자모드 Timezone 설정
            Vue.prototype.$_timezone = function () {
                return 'Asia/Seoul'
            }

            // 하위 소속 명칭 가져오기
            Vue.prototype.$subAgencyName = function () {
                let agency_type = Number(window.sessionStorage.agency_type) + 1
                return CommonAPI._AgencyType.find(item => item.value == agency_type).text
            }

            // 하위 소속 코드 가져오기
            Vue.prototype.$subAgencyType = function () {
                return Number(window.sessionStorage.agency_type) + 1
            }

            // 현재 소속 명칭 가져오기
            Vue.prototype.$agencyName = function () {
                // console.log('&&&', window.sessionStorage.agency_type)
                let agency_type = window.sessionStorage.agency_type
                return CommonAPI._AgencyType.find(item => item.value == agency_type).text
            }

            // 현재 소속 코드 가져오기
            Vue.prototype.$agencyType = function () {
                return Number(window.sessionStorage.agency_type)
            }

            // 현재 소속 ID 가져오기
            Vue.prototype.$agencyId = function () {
                return Number(window.sessionStorage.agency_id)
            }

            // 본사여부체크
            Vue.prototype.$isSuperAgency = function () {
                return Number(window.sessionStorage.level_role) === 90
            }

            // 현재 보고있는 메뉴의 에이전시 타입
            Vue.prototype.$clickAgency = function (num) {
                return Number(num)
            }

            // 회원 계약결과
            Vue.prototype.$myResult = function (status, color) {
                var arr = {
                    'N': '판정전',
                    'W': '실현',
                    'L': '실격',
                    // 'U' : '무효',
                    // 'E' : '오류',
                    'U': '무효',
                    'E': '무효',
                }
                var arr2 = {
                    'N': 'grey',
                    'W': 'red',
                    'L': 'blue',
                    // 'U' : 'grey',
                    // 'E' : 'pink',
                    'U': 'grey',
                    'E': 'grey',
                }
                // selectbox
                var selectArr = {
                    'N': '판정전',
                    'W': '실현',
                    'L': '실격',
                }
                if (status) {
                    if (color) {
                        return arr2[status]
                    } else {
                        return arr[status]
                    }
                } else {
                    var temp = [{value: '', text: '-결과-'}]
                    var result = Object.keys(selectArr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // 프로바이더
            Vue.prototype.$providerName = function (status) {
                var arr = {
                    '1': 'Micro',
                    '2': 'Allbet',
                    '3': 'Taisan',
                    // '4': 'Mega',
                    // '5': 'OG',
                    '6': 'AsianGame',
                    // '7': 'Evolution',
                    '8': 'Habanero',
                    '9': 'QTech',
                    '10': 'Evolution',
                    '11': 'Pragmatic',
                    // '12': 'SuperTrump',
                    // '13': 'HonorLink',
                }

                // 비활성화 프로바이더
                // const disabledPv = ['4', '5']

                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '', text: '전체'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // Sports 프로바이더
            Vue.prototype.$providerNameSports = function (status) {
                var arr = {
                    '1001': 'SBO',
                    '1002': 'BTi',
                    '1003': 'Pinnacle',
                }

                // 비활성화 프로바이더
                // const disabledPv = ['4', '5']

                if (status) {
                    return arr[status]
                } else {
                    var temp = []
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            //이미지등록 프로바이더
            Vue.prototype.$providerNameImg = function (status) {
                var arr = {
                    '1': 'Micro',
                    // '2': 'Allbet',
                    // '3': 'Taisan',
                    // '4': 'Mega',
                    // '5': 'OG',
                    // '6': 'AsianGame',
                    // '7': 'Evolution',
                    // '8': 'Habanero',
                    // '9': 'QTech',
                    // '10': 'Evolution(본사)',
                    // '11': 'Pragmatic',
                    // '12': 'SuperTrump',
                    // '13': 'HonorLink',
                }

                // 비활성화 프로바이더
                // const disabledPv = ['4', '5']

                if (status) {
                    return arr[status]
                } else {
                    var temp = []
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // 유저생성 상태
            Vue.prototype.$createUserStatus = function (status) {
                var arr = {
                    '1': '생성완료',
                    '2': '생성실패',
                    '3': '오류',
                }
                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '0', text: '전체'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }
            // 유저확인 상태
            Vue.prototype.$checkUserStatus = function (status) {
                var arr = {
                    '1': '정상',
                    '2': '오류',
                }
                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '0', text: '전체'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // 입금 상태
            Vue.prototype.$depositStatus = function (status) {
                var arr = {
                    '1': '입금완료',
                    '2': '입금실패',
                }
                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '0', text: '전체'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }
            // 출금 상태
            Vue.prototype.$withdrawalStatus = function (status) {
                var arr = {
                    '1': '출금완료',
                    '2': '출금실패',
                }
                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '0', text: '전체'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }
            // 게임시작 종
            Vue.prototype.$gameType = function (status) {
                var arr = {
                    '11': 'PC Game',
                    '12': 'Mobile Game',
                    '21': 'Slot PC',
                    '22': 'Slot Mobile',
                    '31': 'Slot Demo PC',
                    '32': 'Slot Demo Mobile',
                }
                if (status) {
                    return arr[status]
                } else {
                    var temp = [{value: '', text: '전체게임'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // 지점변경신청상태
            Vue.prototype.$agencyChangeStatus = function (status, color) {
                var arr = {
                    'W': '대기',
                    'R': '거절',
                    'C': '취소',
                    'F': '완료',
                }
                var arr2 = {
                    'W': 'blue',
                    'R': 'red',
                    'C': 'red',
                    'F': 'indigo',
                }
                if (status) {
                    if (color) {
                        return arr2[status]
                    } else {
                        return arr[status]
                    }
                } else {
                    var temp = [{value: '', text: '-상태-'}]
                    var result = Object.keys(arr).map(function (key) {
                        return {value: key, text: arr[key]}
                    })
                    return temp.concat(result)
                }
            }

            // 권한체크
            Vue.prototype.$levelCheck = function (auth) {
                if (this.g_level_role == 90) return true // 최고관리자

                let lvAccess = 0
                let path = this.$nuxt.$route.path
                let level = this.g_level_role
                this.g_gnvs.forEach(function (element) {

                    if (element.role > level) {
                        return false
                    }

                    if (element.to == '/main') {
                        lvAccess = 1
                        return false
                    } else {
                        if (element.submenu) {
                            element.submenu.forEach(ele => {
                                var lvl = ele['Lv' + level]
                                if (lvl !== undefined) {
                                    if (ele.to == path) {
                                        lvAccess = lvl[auth]
                                        return false
                                    }
                                }
                            })
                        } else {
                            if (element.role >= level) {
                                lvAccess = 1
                                return false
                            }
                        }
                    }
                })
                return lvAccess == 1 ? true : false
            }

            // 전역 컴포넌트 옵션 주입
            Vue.mixin({
                data() {
                    return {
                        g_level_role: null,
                        g_gnvs: CommonAPI._GnvsOrigin,
                    }
                },
                created() {
                    this.g_level_role = window.sessionStorage.level_role
                },
                mounted() {
                    // this.g_level_role = window.sessionStorage.level_role
                    // console.log('######',this.g_level_role)
                },
            })


        }
    }

    Vue.use(CommonPlugin)
}
