import axios from '~/utils/axios'
import {getTokenFromLocalStorage, getLanguageFromLocalStorage} from '~/utils/auth'

export default {

    // 관리자 메뉴
    _Gnvs: [],
    _GnvsOrigin: [
        {icon: 'apps', title: 'Dashboard', to: '/main', admin: false, active: false, role: 50 },
        {icon: 'storage', title: '배팅내역', to: '/game/betLog', admin: false, active: false, role: 10 },
        {icon: 'storage', title: '배팅내역(New)', to: '/game/betLogData', admin: false, active: false, role: 10 },
        {icon: 'storage', title: '스포츠 배팅내역', to: '/game/betLogSports', admin: false, active: false, role: 10 },
        // {icon: 'area_chart', title: '배팅통계', to: '/game/betLogStats', admin: true, active: false, role: 90 },
        {icon: 'sync_problem', title: '수집로그', to: '/settings/collectLog', admin: true, active: false, role: 90 },
        {icon: 'person', title: '유저생성', to: '/game/createUser', admin: false, active: false, role: 50 },
        {icon: 'check', title: '유저확인', to: '/game/checkUser', admin: false, active: false, role: 50 },
        // {icon: 'credit_card', title: '입금내역', to: '/game/deposit', admin: false, active: false, role: 10 },
        // {icon: 'account_balance_wallet', title: '출금내역', to: '/game/withdrawal', admin: false, active: false, role: 10 },
        {icon: 'account_balance_wallet', title: '입출내역', to: '/game/transfer', admin: false, active: false, role: 10 },
        {icon: 'monitor', title: '게임시작', to: '/game/startGame', admin: false, active: false, role: 50 },
        {icon: 'image', title: '이미지관리', to: '/game/image', admin: false, active: false, role: 10 },
        {icon: 'list', title: '게임목록관리', to: '/game/providerGames', admin: false, active: false, role: 50 },
        // {icon: 'history', title: '조회내역', to: '/game/history', admin: false, active: false, role: 10 },
        {
            icon: 'account_tree', title: '클라이언트 설정', to: '#', admin: true, role: 50, active: false, submenu: [
                {
                    menu: true,
                    title: '클라이언트',
                    to: '/client/list',
                    Lv50: {R: 1, E: 0},
                    Lv85: {R: 1, E: 0},
                },
                {
                    menu: true,
                    title: '클라이언트 에이전트',
                    to: '/client/agent',
                    Lv50: {R: 1, E: 0},
                    Lv85: {R: 1, E: 0},
                }
            ]
        },
        // {
        //     icon: 'account_tree', title: 'EG 본사 설정', to: '#', admin: true, role: 50, active: false, submenu: [
        //         {
        //             menu: true,
        //             title: '에이전트',
        //             to: '/agency/list',
        //             Lv50: {R: 1, E: 0},
        //             Lv85: {R: 1, E: 0},
        //         },
        //         {
        //             menu: true,
        //             title: '에이전트 사이트',
        //             to: '/agency/site',
        //             Lv50: {R: 1, E: 0},
        //             Lv85: {R: 1, E: 0},
        //         }
        //     ]
        // },
        {icon: 'settings', title: '기본설정', to: '#', admin: false, role: 90, active: false, submenu: [
            {
                menu: true,
                title: 'API 설정',
                to: '/settings/collectors',
                Lv50: {R: 0, E: 0},
                Lv85: {R: 1, E: 0},
            },
            {
                menu: true,
                title: '배팅로그 카테고리 관리',
                to: '/settings/category',
                Lv50: {R: 0, E: 0},
                Lv85: {R: 1, E: 0},
            },
            ]
        },
        {
            icon: 'security', title: '보안설정', to: '#', admin: true, role: 90, active: false, submenu: [
                {
                    menu: true,
                    title: '관리자연결 IP 관리',
                    to: '/auth/connectionIp',
                    Lv50: {R: 0, E: 0},
                    Lv85: {R: 0, E: 0},
                },
                {
                    menu: true,
                    title: '접근차단 IP 관리',
                    to: '/auth/blockingIp',
                    Lv50: {R: 0, E: 0},
                    Lv85: {R: 0, E: 0},
                },
                {
                    menu: true,
                    title: '관리자 리스트',
                    to: '/manager/manager',
                    Lv50:{R:0, E:0},
                    Lv85:{R:0, E:0},
                },
                {
                    menu: true,
                    title: '활동 로그',
                    to: '/manager/activity',
                    Lv50:{R:0, E:0},
                    Lv85:{R:1, E:0},
                }
            ]
        },
    ],

    _AgencyType: [
        {text: '본사', value: 0},
        {text: '부본사', value: 1},
        {text: '총판', value: 2},
        {text: '지사', value: 3},
        {text: '지점', value: 4},
    ],
    _AgencySearchType1: [
        {text: '전체', value: ''},
        {text: '부본사', value: 1}
    ],
    _AgencySearchType2: [
        {text: '전체', value: ''},
        {text: '부본사', value: 1},
        {text: '총판', value: 2},
        // {text : '지사', value : 3 },
        // {text : '지점', value : 4 },
        // {text : '회원', value : 0 },
    ],
    _AgencySearchType3: [
        {text: '전체', value: ''},
        {text: '부본사', value: 1},
        {text: '총판', value: 2},
        {text: '지사', value: 3},
        // {text : '지점', value : 4 },
        // {text : '회원', value : 0 },
    ],
    _AgencySearchType4: [
        {text: '전체', value: ''},
        {text: '부본사', value: 1},
        {text: '총판', value: 2},
        {text: '지사', value: 3},
        {text: '지점', value: 4},
        // {text : '회원', value : 0 },
    ],
    _AgencySearchType0: [
        {text: '전체', value: ''},
        {text: '부본사', value: 1},
        {text: '총판', value: 2},
        {text: '지사', value: 3},
        {text: '지점', value: 4},
        {text: '회원', value: 0},
    ],
    // 게시판 설정
    _Board: {
        faq: {code: 'faq', title: 'FAQ'},
        notice: {code: 'notice', title: '공지사항'},
        event: {code: 'event', title: '이벤트'},
        qna: {code: 'qna', title: '1:1문의'},
    },

    // 관리자 회원
    _ManagerLevelRole: [
        {text: '관리자', value: '90'},
        {text: '부관리자', value: '85'},
        {text: '직원', value: '50'},
        {text: '에이전시', value: '10'},
    ],

    _ManagerRoleStatus: [
        {text: '접근가능', value: '0'},
        {text: '접근불가', value: '1'},
    ],
    _MemberSearchStatusAgency: [
        {text: '상태', value: ''},
        // { text: '대기', value:'J'},
        {text: '정상', value: 'N'},
        // { text: '거절', value:'R'},
        {text: '제재', value: 'S'},
        // { text: '탈퇴', value:'W'},
    ],
    _MemberSearchStatus: [
        {text: '회원상태', value: ''},
        {text: '대기', value: 'J'},
        {text: '정상', value: 'N'},
        {text: '거절', value: 'R'},
        {text: '제재', value: 'S'},
        // { text: '탈퇴', value:'W'},
    ],
    _MemberStatus: [
        // { text: '전체', value:''},
        {text: '대기', value: 'J'},
        {text: '정상', value: 'N'},
        {text: '거절', value: 'R'},
        {text: '제재', value: 'S'},
        // { text: '탈퇴', value:'W'},
    ],
    _AgencyStatus: [
        // { text: '전체', value:''},
        // { text: '대기', value:'J'},
        {text: '정상', value: 'N'},
        // { text: '거절', value:'R'},
        {text: '제재', value: 'S'},
        // { text: '탈퇴', value:'W'},
    ],
    _MemberAccountStatus: [
        {text: '전체', value: ''},
        {text: '등록대기', value: 'W', icon: 'access_time', color: 'blue'},
        {text: '등록거절', value: 'R', icon: 'block', color: 'red'},
        {text: '등록완료', value: 'F', icon: 'check_circle', color: 'green'},
        {text: '사용종료', value: 'E', icon: 'close', color: 'orange'},
    ],
    _MemberLevelCert: [
        {text: 'Lv 1 : 이메일 인증', value: '1', name: 'lv1', desc: '회원가입 승인 메일 완료된 회원'},
        {text: 'Lv 2 : KYC 실명인증', value: '2', name: 'lv2', desc: 'KYC 실명 인증이 완료된 회원'},
        // { text: 'Lv 3 : 실명계좌 인증 완료', value:'3', name:'lv3', desc:'실명계좌 인증 완료된 회원'},
        // { text: 'Lv 4 : OTP 인증 완료', value:'4', name:'lv4', desc:'OTP앱 다운로드 후 OTP 등록한 회원'},
    ],

    _AccountSendStatus: [
        {text: '전체', value: ''},
        {text: '승인대기', value: 'W'},
        // { text: '처리중', value:'P'},
        {text: '승인완료', value: 'F'},
        {text: '승인거절', value: 'R'},
        {text: '취소', value: 'E'},
    ],
    _AccountSendType: [
        {text: '전체', value: ''},
        {text: 'Personal', value: '1'},
        {text: 'Manager Send', value: '2'},
        {text: 'Stake Airdrop', value: '3'},
        {text: 'Mining Airdrop', value: '4'},
        {text: '기축통화 입금', value: '5'},
        {text: '기축통화 출금', value: '6'},
    ],
    _CurrencyState: [
        {text: '상태', value: '', color: ''},
        {text: '대기', value: 'W', color: 'purple'},
        // { text: '처리중', value:'P', color:'green'},
        {text: '완료', value: 'F', color: 'blue'},
        {text: '거절', value: 'R', color: 'red'},
        {text: '취소', value: 'E', color: 'pink'},
    ],

    _CurrencyInState: [
        {text: '전체', value: ''},
        // { text: '미인증', value:'N', color: 'grey'},
        {text: '입금신청', value: 'W', color: 'blue'},
        {text: '문자완료', value: 'S', color: 'yellow'},
        {text: '입금완료', value: 'P', color: 'grey'},
        {text: '승인완료', value: 'F', color: 'green'},
        {text: '승인거절', value: 'R', color: 'red'},
        {text: '신청취소', value: 'C', color: 'indigo'},
        {text: '오류', value: 'E', color: 'red'},
    ],

    _CurrencyOutState: [
        {text: '전체', value: ''},
        {text: '미인증', value: 'N', color: 'grey'},
        {text: '승인대기', value: 'W', color: 'blue'},
        // { text: '처리중', value:'P', color: 'grey'},
        {text: '승인완료', value: 'F', color: 'green'},
        {text: '승인거절', value: 'R', color: 'red'},
        {text: '신청취소', value: 'C', color: 'indigo'},
        {text: '오류', value: 'E', color: 'red'},
    ],

    _CurrencyStateDialog: [
        // { text: '상태', value:'', color:''},
        // { text: '대기', value:'W', color:'purple' },
        // { text: '처리중', value:'P', color:'green'},
        {text: '승인완료', value: 'F'},
        {text: '승인거절', value: 'R'},
        // { text: '승인취소', value:'E'},
    ],
    _currencyTF: [
        {text: '현금거래', value: ''},
        {text: '현금', value: 'true'},
        {text: '코인', value: 'false'},
    ],

    getBaseCode: function (payload = null) {
        return new Promise((resolve) => {
            axios.post(window.sessionStorage.api_base_url + '/codes/getBaseCode/' + payload.group_id, {}, {
                headers: {
                    "Admincryptolization": !!getTokenFromLocalStorage() ? getTokenFromLocalStorage() : "",
                    "Language": !!getLanguageFromLocalStorage() ? getLanguageFromLocalStorage() : ""
                }
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },

    getApiUrl: function () {
        return new Promise((resolve) => {
            resolve(true)
            return
            axios.get('config/config.json')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                })
        })
    },
}
